<template>
  <div>
    <v-switch
      v-bind="$attrs"
      v-on="$listeners"
      v-model="tempValue"
      :dense="dense"
      :class="{ 'switchOnRight': switchOnRight }"
      class="mt-0"
    >
      <template #label>
        <!--
          This description should be outside of the label for accessibility reasons.
          While we dont have a solution for now with te current v-switch component,
          we can use this workaround.
        -->
        <eva-label
          :label="label"
          :subtitle="description"
          :disabled="$attrs.disabled"
        />
      </template>
    </v-switch>
  </div>
</template>

<script>
import EvaLabel from "@/components/shared/inputs/eva-label.vue"

export default {
  name: "EvaSwitch",
  components: { EvaLabel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    switchOnRight: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tempValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>

<style scoped>

.switchOnRight::v-deep .v-input__slot{
  display: flex;
  flex-direction: row-reverse;
}

</style>
